body {
  margin: 0;
  font-family: "montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1a1a;
  color: #d4d4d4;
}

@font-face {
  font-family: "montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  /* src: url("./fonts/Montserrat-Regular.ttf") format("woff2"); */
  src: url("./fonts/Montserrat-VariableFont_wght.ttf") format("woff2");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
